import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ROADWAY_CLASSIFICATIONS } from '../../../Utils/Constants';
import {
  getColorCode,
  getCorridorTierColors,
  getMetricTier,
  getOverallScore,
  getTierIntervalsPerMetric,
} from '../../../Utils/CorridorRankingHelper';
import { localStorageGetWithExpiry } from '../../../Utils/LocalStorage';
import TableWithPagination from '../../../Components/TableWithPagination/TableWithPagination';
import TableFilter from './TableFilters/TableFilters';
import TableLegend from './TableLegend';

const MapTableOverview = ({ globalObject, refreshMap, districtFilterSW, filteredCorridorList, filterSegment }) => {
  const [extractedData, setExtractedData] = useState([]);
  const [filters, setFilters] = useState({});
  const [customWeights, setCustomWeights] = useState({});
  const [activeMetric, setActiveMetric] = useState('overall');
  const [showValuesInstedOfScores, setShowValuesInstedOfScores] = useState(false);
  const [sortByColumn, setSortByColumn] = useState({ name: '', order: 'desc' });
  const itemsPerPage = 10;

  const scoresArray = useMemo(() => {
    return globalObject?.map(go => getOverallScore(go));
  }, [globalObject, customWeights]);

  useEffect(() => {
    setFilters({ ...filters, district: districtFilterSW });
  }, [districtFilterSW]);

  const handleTableFilter = (type, search) => {
    setSortByColumn(() => {
      return { name: '', order: 'desc' };
    });

    if (type === 'filters') {
      setFilters(search);
      refreshMap();
      filterSegment();
    }

    if (type === 'weights') {
      setCustomWeights(search);
      refreshMap();
      filterSegment();
    }

    if (type === 'metric') {
      setActiveMetric(search);
    }

    if (type === 'switchValueScore') {
      setShowValuesInstedOfScores(search);
    }
  };

  const tierIntervals = useMemo(() => {
    return getTierIntervalsPerMetric(globalObject || [], (globalObject || [])?.length);
  }, [globalObject]);

  const tableViewColumns = useCallback(
    item => {
      const colorCode = getCorridorTierColors(
        scoresArray,
        getOverallScore(item), //?.toFixed(2)
      );

      const Scr_Tot = getOverallScore(item)?.toFixed(2);

      return {
        'Overall Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Overall <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            textAlign: 'center',
            color: colorCode.color,
            backgroundColor: colorCode.backgroundColor,
          },
          __value: Scr_Tot,
          __onClick: column => handleClick(column),
        },
        'Pavement Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Pavement <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_1?.toFixed(2), tierIntervals.pavement)),
            textAlign: 'center',
          },
          __value: item.Scr_1?.toFixed(2),
          __onClick: column => handleClick(column),
        },
        'Bridge Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Bridge <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_2?.toFixed(2), tierIntervals.bridge)),
            textAlign: 'center',
          },
          __value: item.Scr_2?.toFixed(2),
          __onClick: column => handleClick(column),
        },
        'Safety Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Safety <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_3?.toFixed(2), tierIntervals.safety)),
            textAlign: 'center',
          },
          __value: item.Scr_3?.toFixed(2),
          __onClick: column => handleClick(column),
        },
        'Congestion Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Congestion <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_4?.toFixed(2), tierIntervals.congestion)),
            textAlign: 'center',
          },
          __value: item.Scr_4?.toFixed(2),
          __onClick: column => handleClick(column),
        },
        'Economic Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Economic <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_5?.toFixed(2), tierIntervals.economic)),
            textAlign: 'center',
          },
          __value: item.Scr_5?.toFixed(2),
          __onClick: column => handleClick(column),
        },
        'Connectivity Score': {
          __headerStyle: { textAlign: 'left' },
          __label: `
          <div class="column-header">
            <div>
              Connectivity <br /> Score
            </div>
          
            <i class="fa-solid fa-sort" style="color: #fff;"></i>
          </div>`,
          __style: {
            ...getColorCode(getMetricTier(item.Scr_6?.toFixed(2), tierIntervals.connectivity)),
            textAlign: 'center',
          },
          __value: item.Scr_6?.toFixed(2),
          __onClick: column => handleClick(column),
        },
      };
    },
    [activeMetric, scoresArray, showValuesInstedOfScores, tierIntervals, customWeights],
  );

  useEffect(() => {
    const missingCorridors = [];

    if (globalObject && globalObject?.length > 0) {
      const extractedValues = globalObject.map(item => {
        const {
          Corridor_ID,
          Begin_Milepost,
          End_Milepost,
          Districts,
          MPO,
          Scr_1,
          Scr_2,
          Scr_3,
          Scr_4,
          Scr_5,
          Scr_6,
          Custom,
        } = item;

        let temp = undefined;
        let sortingValue = 0;

        if (filteredCorridorList?.length > 0) {
          temp = filteredCorridorList.find(t => t.Id === Corridor_ID);

          if (!temp) {
            temp = { BMP: 0, EMP: 0, Score_Change: 0, Trend: 0 };
            missingCorridors.push(Corridor_ID);
          }
        }

        let trend;

        if (temp && temp['Trend'] !== undefined) {
          switch (temp['Trend']) {
            case 'Increasing':
              trend = (
                <span className="trend-icon icon-up">
                  <i className="fa-solid fa-arrow-up"></i>
                </span>
              );
              sortingValue = 3;
              break;
            case 'Decreasing':
              trend = (
                <span className="trend-icon icon-down">
                  <i className="fa-solid fa-arrow-down"></i>
                </span>
              );
              sortingValue = 1;
              break;
            default:
              trend = (
                <span className="trend-icon">
                  <i className="fa-solid fa-minus"></i>
                </span>
              );
              sortingValue = 2;
              break;
          }
        }

        return {
          Id: { __value: Corridor_ID, __hide: true },
          Route: {
            __label: `
            <div class="column-header">
              <div>
                Route
              </div>
            
              <i class="fa-solid fa-sort" style="color: #fff;"></i>
            </div>`,
            __headerStyle: { textAlign: 'left' },
            __style: { textAlign: 'left' },
            __value: `${item['HWY']} (${item['Description']})`,
            __onClick: column => handleClick(column),
          },
          'Begin Marker': {
            __headerStyle: { textAlign: 'left' },
            __style: { textAlign: 'center' },
            __value: Begin_Milepost,
          },
          'End Marker': {
            __headerStyle: { textAlign: 'left' },
            __style: { textAlign: 'center' },
            __value: End_Milepost,
          },
          'Rank Overall': {
            __value: 0,
          },
          'Score Change': {
            __label: `
            <div class="column-header">
              <div>
                Score Change <br /> from Last Year
              </div>
            
              <i class="fa-solid fa-sort" style="color: #fff;"></i>
            </div>`,
            __headerStyle: { textAlign: 'left' },
            __style: { textAlign: 'center' },
            __value: temp && temp['Score_Change'] !== undefined ? Math.round(temp['Score_Change']) : '',
            __onClick: column => handleClick(column),
          },
          Trend: {
            __label: `
            <div class="column-header">
              <div>
                Trend
              </div>
            
              <i class="fa-solid fa-sort" style="color: #fff;"></i>
            </div>`,
            __headerStyle: { textAlign: 'left' },
            __style: { textAlign: 'center' },
            __value: trend,
            __sortingValue: sortingValue,
            __onClick: column => handleClick(column),
          },
          ...tableViewColumns(item),
          Districts: { __hide: true, __value: Districts },
          MPO: { __hide: true, __value: MPO },
          Scr_1: { __hide: true, __value: Scr_1 },
          Scr_2: { __hide: true, __value: Scr_2 },
          Scr_3: { __hide: true, __value: Scr_3 },
          Scr_4: { __hide: true, __value: Scr_4 },
          Scr_5: { __hide: true, __value: Scr_5 },
          Scr_6: { __hide: true, __value: Scr_6 },
          Custom: Custom,
        };
      });

      setExtractedData(extractedValues);
    }
  }, [globalObject, tableViewColumns]);

  const metricSorting = useCallback(
    (a, b) => {
      switch (activeMetric) {
        case 'overall':
          return parseFloat(a?.['Overall Score']?.__value) < parseFloat(b?.['Overall Score']?.__value) ? 1 : -1;

        case 'pavement':
          return a?.['Pavement Score']?.__value < b?.['Pavement Score']?.__value ? 1 : -1;

        case 'bridge':
          return a?.['Bridge Score']?.__value < b?.['Bridge Score']?.__value ? 1 : -1;

        case 'safety':
          return a?.['Safety Score']?.__value < b?.['Safety Score']?.__value ? 1 : -1;

        case 'congestion':
          return a?.['Congestion Score']?.__value < b?.['Congestion Score']?.__value ? 1 : -1;

        case 'economic':
          return a?.['Economic Score']?.__value < b?.['Economic Score']?.__value ? 1 : -1;

        case 'connectivity':
          return a?.['Connectivity Score']?.__value < b?.['Connectivity Score']?.__value ? 1 : -1;

        default:
          break;
      }
    },
    [activeMetric],
  );

  const handleClick = column => {
    setSortByColumn(prev => {
      return { name: column, order: prev.name === column && prev.order === 'desc' ? 'asc' : 'desc' };
    });
  };

  const sortTableByColumn = (columnA, columnB) => {
    let a, b;

    if (sortByColumn.order === 'desc') {
      a = columnA;
      b = columnB;
    } else {
      a = columnB;
      b = columnA;
    }

    if (sortByColumn.name === 'Route') {
      if (a[sortByColumn.name]?.HWY.toLowerCase() < b[sortByColumn.name]?.HWY.toLowerCase()) {
        return 1;
      }

      if (a[sortByColumn.name]?.HWY.toLowerCase() > b[sortByColumn.name]?.HWY.toLowerCase()) {
        return -1;
      }

      return 0;
    } else if (sortByColumn.name === 'Trend') {
      if (a[sortByColumn.name]?.__sortingValue < b[sortByColumn.name]?.__sortingValue) {
        return 1;
      }

      if (a[sortByColumn.name]?.__sortingValue > b[sortByColumn.name]?.__sortingValue) {
        return -1;
      }

      return 0;
    } else {
      if (parseFloat(a[sortByColumn.name]?.__value) < parseFloat(b[sortByColumn.name]?.__value)) {
        return 1;
      }

      if (parseFloat(a[sortByColumn.name]?.__value) > parseFloat(b[sortByColumn.name]?.__value)) {
        return -1;
      }

      return 0;
    }
  };

  const filteredData = useMemo(() => {
    const roadwayClassifications = filters?.roadwayClassifications?.map(roadway => roadway?.value) || [];
    const includeOthers = roadwayClassifications.includes('OTHERS');

    const updatedData = customWeights
      ? extractedData.sort((a, b) => {
          return parseFloat(a['Overall Score'].__value) < parseFloat(b['Overall Score'].__value) ? 1 : -1;
        })
      : extractedData;

    const filteredArr = updatedData
      .filter(
        rec =>
          roadwayClassifications.length === 0 ||
          roadwayClassifications?.indexOf(rec?.Route.__value.substring(0, 2)) > -1,
      )
      .filter(rec => {
        if (filters?.district && filters?.district !== '') {
          return rec?.Districts?.__value?.includes(filters?.district);
        } else {
          return rec;
        }
      })
      .filter(rec => {
        if (filters?.urbanRural && filters?.urbanRural !== '') {
          return rec?.MPO?.__value?.includes(filters?.urbanRural);
        } else {
          return rec;
        }
      });

    if (includeOthers) {
      const corridorTypes = ROADWAY_CLASSIFICATIONS.map(r => r.value);
      const othersCorridors = extractedData.filter(
        rec =>
          !roadwayClassifications.includes(rec.Route.__value.slice(0, 2)) &&
          !corridorTypes.includes(rec.Route.__value.slice(0, 2)),
      );
      filteredArr.push(...othersCorridors);
    }
    return filteredArr
      .map((corridor, index) => {
        const colorCode = getCorridorTierColors(scoresArray, corridor['Overall Score'].__value);
        return {
          ...corridor,
          Route: {
            ...corridor.Route,
            __value: (
              <div>
                {corridor.Route.__value}
                {corridor.Custom === 1 && (
                  <img
                    src="/assets/svg/SpecialPlus_segments.svg"
                    alt="custom segment icon"
                    className="custom-segment-icon-alone"
                  />
                )}
              </div>
            ),
            HWY: corridor.Route.__value,
          },
          'Overall Score': {
            ...corridor['Overall Score'],
            __style: {
              textAlign: 'center',
              color: colorCode.color,
              backgroundColor: colorCode.backgroundColor,
            },
          },
          'Rank Overall': {
            __label: 'Rank <br /> (Overall)',
            __headerStyle: { textAlign: 'center' },
            __style: { textAlign: 'center' },
            __value: index + 1,
          },
          Custom: {
            __hide: true,
          },
        };
      })
      .sort((a, b) => {
        if (sortByColumn.name === '') {
          return metricSorting(a, b);
        } else {
          return sortTableByColumn(a, b);
        }
      });
  }, [filters, customWeights, extractedData, scoresArray, metricSorting, sortByColumn]);

  useEffect(() => {
    const localStoragefilters = localStorageGetWithExpiry('customFilters');

    if (localStoragefilters) {
      let roadwayClassifications = [];

      ROADWAY_CLASSIFICATIONS.forEach(rec => {
        if (localStoragefilters[rec.value]) {
          roadwayClassifications = [...roadwayClassifications, { ...rec }];
        }
      });

      setFilters(prev => ({
        ...prev,
        roadwayClassifications,
        district: localStoragefilters?.district || '',
        urbanRural: localStoragefilters?.urbanRural || '',
      }));
    }

    const localStorageCustomWeight = localStorageGetWithExpiry('customWeights');

    if (localStorageCustomWeight) {
      setCustomWeights(localStorageCustomWeight);
    }
  }, []);

  return (
    <>
      {filteredCorridorList && (
        <div className="table-wrapper">
          <TableFilter
            handleTableFilter={handleTableFilter}
            filters={filters}
            customWeights={customWeights}
            tableview
            showValueSwitch={activeMetric === 'overall'}
            showValuesInstedOfScores={showValuesInstedOfScores}
          />

          {filteredData && (
            <TableWithPagination
              data={filteredData}
              itemsPerPage={itemsPerPage}
              customWeights={customWeights}
              isPaginationAvail={true}
            />
          )}
          <TableLegend />
        </div>
      )}
    </>
  );
};

export default MapTableOverview;
