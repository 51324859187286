import { useRef, useEffect, useCallback, useState } from 'react';

import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import esriConfig from '@arcgis/core/config';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';

import { resetHighlightLayer, setupMapAndData } from '../../../../Utils/MapHelpers';

import { createPopupTemplate, getCorridorLayerUrl } from '../../../../Utils/Functions';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import {
  DISTRICT_FEATURE_SERVICE_URL,
  COUNTY_FEATURE_SERVICE_URL,
  URBANIZED_AREA_FEATURE_SERVICE_URL,
  MPO_FEATURE_SERVICE_URL,
  DEFAULT_MAP_CENTRAL_POINT,
  DEFAULT_SPATIAL_REF,
  MAP_API_KEY,
  FUTURE_INTERSTATES_FEATURE_SERVICE_URL,
  HURRICANCE_EVACUATION_ROUTE,
  TEXAS_TRUNK_SYSTEM,
  ENERGY_SECTOR_CORRIDORS,
  ROADWAY_SHIELD_ONE,
  ROADWAY_SHIELD_TWO,
  ROADWAY_SHIELD_THREE,
  ROADWAY_SHIELD_FOUR,
} from '../../../../Utils/Constants';
import { useGlobalContext } from '../../../../Context/GlobalContext';

import { getLabelingInfo, addAdditionalSections } from './helper';

function useMapView() {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { selectedMode, selectedDistrict, selectedMPO } = useSegmentEvalutionStore(state => state);
  const { globalObject } = useGlobalContext();

  const mapContainerRef = useRef();
  const mapViewRef = useRef();
  const viewRef = useRef();
  const corridorLayerRef = useRef();
  const graphicsLayerRef = useRef();
  const highlightLayerRef = useRef();

  const refreshMap = useCallback(() => {
    setIsMapLoaded(false);
    if (highlightLayerRef.current) resetHighlightLayer(highlightLayerRef.current);
    // Call the setupMapAndData function
    if (globalObject) {
      setupMapAndData(
        corridorLayerRef.current,
        viewRef.current,
        globalObject,
        selectedMode,
        selectedDistrict,
        selectedMPO,
        false,
      );
    }
    setIsMapLoaded(true);
  }, [globalObject, selectedMode, selectedDistrict, selectedMPO]);

  const generateMap = useCallback(async () => {
    esriConfig.apiKey = MAP_API_KEY;

    // Create the corridor layer
    const corridorLayer = new FeatureLayer({
      url: getCorridorLayerUrl(selectedMode),
      popupTemplate: createPopupTemplate(),
      title: 'Corridor Assessment',
      id: 'prioritization',
      dockEnabled: false,
      labelingInfo: getLabelingInfo(),
      // definitionExpression: selectedMode === 'District' ? `Districts = '${selectedDistrict}'` : null,
      definitionExpression: null,
    });

    // Create the highlight layer
    const highlightLayer = new FeatureLayer({
      url: getCorridorLayerUrl(selectedMode),
      title: 'highlight',
      id: 'highlight',
      listMode: 'hide',
    });

    // Initialize a GraphicsLayer
    const graphicsLayer = new GraphicsLayer({
      title: 'User-defined Corridors',
    });

    // Create the context layers
    const districtLayer = new FeatureLayer({
      url: DISTRICT_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'District Boundaries',
      renderer: {
        type: 'simple',
        symbol: {
          type: 'simple-line',
          width: 1,
          color: 'lightGray',
        },
      },
    });

    const urbanizedAreaLayer = new FeatureLayer({
      url: URBANIZED_AREA_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'Urbanized Areas',
      visible: false,
    });

    const countyLayer = new FeatureLayer({
      url: COUNTY_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'County Boundaries',
      visible: false,
    });

    const mpoLayer = new FeatureLayer({
      url: MPO_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'MPO Boundaries',
      visible: false,
    });

    const fiLayer = new FeatureLayer({
      url: FUTURE_INTERSTATES_FEATURE_SERVICE_URL,
      outFields: ['*'],
      title: 'Future Interstates',
      visible: false,
    });
    const ttsLayer = new FeatureLayer({
      url: TEXAS_TRUNK_SYSTEM,
      outFields: ['*'],
      title: 'Texas Trunk System',
      visible: false,
    });
    const herLayer = new FeatureLayer({
      url: HURRICANCE_EVACUATION_ROUTE,
      outFields: ['*'],
      title: 'Hurricane Evacuation Route',
      visible: false,
    });
    const escLayer = new FeatureLayer({
      url: ENERGY_SECTOR_CORRIDORS,
      outFields: ['*'],
      title: 'Energy Sector Corridors',
      visible: false,
    });

    const rw1 = new FeatureLayer({
      url: ROADWAY_SHIELD_ONE,
      outFields: ['*'],
      title: 'Roadway 1',
      visible: true,
      listMode: 'hide',
    });
    const rw2 = new FeatureLayer({
      url: ROADWAY_SHIELD_TWO,
      outFields: ['*'],
      title: 'Roadway 2',
      visible: true,
      listMode: 'hide',
    });
    const rw3 = new FeatureLayer({
      url: ROADWAY_SHIELD_THREE,
      outFields: ['*'],
      title: 'Roadway 3',
      visible: true,
      listMode: 'hide',
    });
    const rw4 = new FeatureLayer({
      url: ROADWAY_SHIELD_FOUR,
      outFields: ['*'],
      title: 'Roadway 4',
      visible: true,
      listMode: 'hide',
    });

    // Create the map
    const map = new Map({
      basemap: 'arcgis-light-gray',
      layers: [
        ttsLayer,
        herLayer,
        escLayer,
        urbanizedAreaLayer,
        fiLayer,
        mpoLayer,
        countyLayer,
        districtLayer,
        corridorLayer,
        highlightLayer,
        graphicsLayer,
        rw1,
        rw2,
        rw3,
        rw4,
      ],
      spatialReference: DEFAULT_SPATIAL_REF,
    });

    // Create the map view
    const view = new MapView({
      map: map,
      center: DEFAULT_MAP_CENTRAL_POINT,
      zoom: 4.75,
      container: mapContainerRef.current,
      constraints: {
        snapToZoom: false,
      },
    });

    addAdditionalSections(view, countyLayer, districtLayer, corridorLayer);

    // Initialize Refs values
    corridorLayerRef.current = corridorLayer;
    mapViewRef.current = map;
    viewRef.current = view;
    graphicsLayerRef.current = graphicsLayer;
    highlightLayerRef.current = highlightLayer;
    return () => {
      view.destroy();
    };
  }, []);

  useEffect(() => {
    (async () => {
      generateMap();
      refreshMap();
    })();
  }, [generateMap, refreshMap]);

  return {
    mapContainerRef,
    mapViewRef,
    viewRef,
    corridorLayerRef,
    graphicsLayerRef,
    highlightLayerRef,
    refreshMap,
    isMapLoaded,
  };
}

export default useMapView;
