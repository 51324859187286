import React, { useMemo, useState, useEffect } from 'react';
import './SummaryReportPreview.css';
import { useNavigate, useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { imagedata_to_image } from '../../Utils/printViewMapScript';
import useMapView from '../../Features/CorridorAssessment/AssessmentResult/Hooks/useMapView';
import useCustomSegments from '../../Features/CorridorAssessment/AssessmentResult/Hooks/useCustomSegments';
import { getOverallScore, getCorridorTierColors } from '../../Utils/CorridorRankingHelper';
import { convertMultiLineStringToGraphic } from '../../Utils/MapHelpers';
import { getColorCode, getMetricTier, getTierIntervalsPerMetric } from '../../Utils/CorridorRankingHelper';
import { generatePartialCSV } from '../../Utils/TableExportHelper';
import { localStorageGetWithExpiry } from '../../Utils/LocalStorage';
import { useGlobalContext } from '../../Context/GlobalContext';

const SummaryReportPreview = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { globalObject, summaryReportData: data } = useGlobalContext();
  const customWeights = localStorageGetWithExpiry('customWeights') || null;
  const tierIntervals = useMemo(
    () => getTierIntervalsPerMetric(globalObject || [], (globalObject || [])?.length),
    [globalObject],
  );
  const { filteredCustomSegments = [] } = useCustomSegments();
  const { mapContainerRef, viewRef, graphicsLayerRef, isMapLoaded } = useMapView();

  const groupedData = () => {
    let perChunk = 20;
    return data.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  };

  const navigate = useNavigate();
  const onNavigateBack = () => {
    navigate(-1);
  };

  const onExport = () => {
    generatePartialCSV(globalObject);
  };

  const onPrint = async () => {
    setIsLoading(true);
    let jsPdf = new jsPDF('p', 'pt', 'letter', true);
    await convert_to_static_map(viewRef.current);
    let summaryReportContainers = document.getElementsByClassName('summary-report-container');
    for (let i = 0; i < summaryReportContainers.length; i++) {
      let canvas = await html2canvas(summaryReportContainers[i], { scale: 1 });
      const imgData = canvas.toDataURL('image/png');
      jsPdf.addImage({ imageData: imgData, compression: 'FAST' });
      if (jsPdf.internal.getNumberOfPages() < summaryReportContainers.length) {
        jsPdf.addPage();
      }
    }
    setIsLoading(false);
    jsPdf.save('summary-report.pdf');
  };

  const convert_to_static_map = async mapview => {
    if (!mapview) return;
    const screenshot = await mapview?.takeScreenshot();
    const image = imagedata_to_image(screenshot?.data);
    if (!image) return;
    document.getElementById('viewDiv-print').style.display = 'none';
    document.getElementById('static-map').className = 'summary-map';
    const hasChilds = document.getElementById('static-map').hasChildNodes();
    if (!hasChilds) document.getElementById('static-map').appendChild(image);
  };

  useEffect(() => {
    if (isMapLoaded) {
      graphicsLayerRef.current.removeAll();
      if (filteredCustomSegments?.length > 0) {
        const graphics = [];
        filteredCustomSegments.forEach(segment => {
          const geometry = segment?.Geometry;
          const ob = JSON.parse(geometry);
          const segmentGraphics = ob.features.map(feature => {
            if (feature.geometry.type === 'MultiLineString') {
              const scoresArray = globalObject?.map(go => getOverallScore(go));
              const overallScore = getOverallScore(segment);
              const colorCode = getCorridorTierColors(scoresArray, overallScore);
              const color = colorCode.backgroundColor === '#FFFFFF' ? 'gray' : colorCode.backgroundColor;
              return convertMultiLineStringToGraphic(feature, { ...segment, Scr_Tot: getOverallScore(segment) }, color);
            }
          });
          graphics.push(...segmentGraphics);
        });
        graphicsLayerRef.current.addMany(graphics);
      }
    }
  }, [filteredCustomSegments, globalObject]);

  return (
    <>
      {isLoading && <LoadingSpinner label="Generating PDF..." />}
      <div className="container-fluid" id="summary-report-main-container" style={{ backgroundColor: 'gray' }}>
        <div className="row summary-report-container">
          <div className="summary-title">
            <i className="fa-solid fa-arrow-left" onClick={onNavigateBack} data-html2canvas-ignore="true"></i>
            Corridor Assessment Summary Report
          </div>

          <div className="action-buttons" data-html2canvas-ignore="true">
            {/* <button onClick={onExport}>
              <i className="fa-solid fa-download"></i>Export
            </button> */}
            <button onClick={onPrint}>
              <i className="fa-solid fa-print"></i>Print
            </button>
          </div>

          <div className="summary-header">
            <div className="title">What is being measured?</div>
            <div className="row">
              <div className="col-2">
                <img src="/assets/img/Pavement.png" alt="Pavement" className="header-image" />
                <span>Pavement</span>
              </div>
              <div className="col-2">
                <img src="/assets/img/Bridge.png" alt="Bridge" className="header-image" />
                <span>Bridge</span>
              </div>
              <div className="col-2">
                <img src="/assets/img/Safety.png" alt="Safety" className="header-image" />
                <span>Safety</span>
              </div>
              <div className="col-2">
                <img src="/assets/img/Congestion.png" alt="Congestion" className="header-image" />
                <span>Congestion</span>
              </div>
              <div className="col-2">
                <img src="/assets/img/Economic.png" alt="Economic" className="header-image" />
                <span>Economic</span>
              </div>
              <div className="col-2">
                <img src="/assets/img/Connectivity.png" alt="Connectivity" className="header-image" />
                <span>Connectivity</span>
              </div>
            </div>
          </div>

          <div className="summary-map" id="viewDiv-print">
            <div ref={mapContainerRef} className="map-container" style={{ height: '100%', overflow: 'hidden' }}></div>
          </div>
          <div className="d-none summary-map" id="static-map"></div>
        </div>

        {groupedData().map((group, index) => (
          <div key={index} className="summary-report-container table-container">
            <div className="summary-table-header">
              <div className="label">
                <i className="fa-solid fa-sliders"></i>
                Weights
              </div>
              <div className="weight">{customWeights?.pavement}%</div>
              <div className="weight">{customWeights?.bridge}%</div>
              <div className="weight">{customWeights?.safety}%</div>
              <div className="weight">{customWeights?.congestion}%</div>
              <div className="weight">{customWeights?.economic}%</div>
              <div className="weight">{customWeights?.connectivity}%</div>
            </div>
            <table className="table mt-3">
              <thead>
                <tr>
                  <th scope="col">Rank</th>
                  <th scope="col">Route</th>
                  <th scope="col">BMP</th>
                  <th scope="col">EMP</th>
                  <th scope="col">
                    Overall Score
                    <br />
                    (0-100)
                  </th>
                  <th scope="col">
                    Pavement Score
                    <br />
                    (0-10)
                  </th>
                  <th scope="col">
                    Bridge Score
                    <br />
                    (0-10)
                  </th>
                  <th scope="col">
                    Safety Score
                    <br />
                    (0-10)
                  </th>
                  <th scope="col">
                    Congestion Score
                    <br />
                    (0-10)
                  </th>
                  <th scope="col">
                    Economic Score
                    <br />
                    (0-10)
                  </th>
                  <th scope="col">
                    Connectivity Score
                    <br />
                    (0-10)
                  </th>
                </tr>
              </thead>
              <tbody>
                {group?.map((corridor, index) => (
                  <tr key={index}>
                    <td>{data.indexOf(corridor) + 1}</td>
                    <td>{corridor.Route}</td>
                    <td style={{ textAlign: 'center' }}>{corridor['Begin Marker'].__value}</td>
                    <td style={{ textAlign: 'center' }}>{corridor['End Marker'].__value}</td>
                    <td
                      style={{
                        ...corridor['Overall Score']?.__style,
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Overall Score'].__value}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_1.__value, tierIntervals.pavement)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_1'].__value?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_2.__value, tierIntervals.bridge)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_2'].__value?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_3.__value, tierIntervals.safety)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_3'].__value?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_4.__value, tierIntervals.congestion)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_4'].__value?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_5.__value, tierIntervals.economic)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_5'].__value?.toFixed(2)}
                    </td>
                    <td
                      style={{
                        ...getColorCode(getMetricTier(corridor.Scr_6.__value, tierIntervals.connectivity)),
                        textAlign: 'center',
                      }}
                    >
                      {corridor['Scr_6'].__value?.toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
};

export default SummaryReportPreview;
