import Cookies from 'js-cookie';

// Get cookie by name
export function getCookie(name) {
  return Cookies.get(name);
}

// Set cookie with options like expiration, secure, and sameSite
export function setCookie(name, value, options = {}) {
  Cookies.set(name, value, {
    expires: options.expires || 1 / 48, // Default to 30 minutes (1/48th of a day)
    secure: options.secure || true, // Default to secure
    sameSite: options.sameSite || 'None', // Default to 'None'
    ...options, // Allow other options to be passed
  });
}

// Remove cookie by name
export function removeCookie(name) {
  Cookies.remove(name);
}
