import { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { toPercentage } from '../../../../Utils/Functions';
import { useGlobalContext } from '../../../../Context/GlobalContext';
import { getOverallScore } from '../../../../Utils/CorridorRankingHelper';

const CorridorInformation = ({ highlightCorridorId, setHighlightCorridorId, onClickBack, onClickZoom }) => {
  const [open, setOpen] = useState('1');
  const { globalObject } = useGlobalContext();

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const corridorObject = globalObject?.filter(x => x.Corridor_ID === highlightCorridorId)[0] || null;

  return (
    <div className="container" id="dataDiv2">
      <div className="row">
        <div className="col-lg-1">
          <div className="btn btn-lg">
            <button
              className="page-link"
              onClick={() => {
                setHighlightCorridorId(null);
                onClickBack();
              }}
            >
              <i className="fa-solid fa-arrow-left fa-lg" />
            </button>
          </div>
        </div>
        <div className="col-lg-10">
          <div className="d-flex justify-content-center">
            <h4 className="pt-2">CORRIDOR INFORMATION</h4>
          </div>
        </div>
        <div className="px-3 mx-0">
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 px-5">Select corridor:</div>
          <div className="col-lg-6 px-5">
            <select className="form-select" id="floatingSelect">
              <option value="" selected>
                {highlightCorridorId}
              </option>
            </select>
          </div>
          <div className="col-lg-2 px-5">
            <div className="btn me-4 text-secondary" id="zoom-button" onClick={onClickZoom}>
              <i className="fa-solid fa-magnifying-glass-plus mx-2" />
              Zoom
            </div>
          </div>
        </div>
        <div id="basic-info" className="table px-5">
          <div className="row">
            <div className="col-lg-6">
              <div className="text-start ps-2">Description</div>
            </div>
            <div className="col-lg-6">
              <div id="basic-info-despn" className="text-end pe-2">
                {corridorObject.Description}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-start ps-2">Length (Miles)</div>
            </div>
            <div className="col-lg-6">
              <div id="basic-info-length" className="text-end pe-2">
                {Math.round(corridorObject.HWY_LEN * 0.000621371, 1)}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-start ps-2">Begin Reference Marker</div>
            </div>
            <div className="col-lg-6">
              <div id="basic-info-bmp" className="text-end pe-2">
                {corridorObject.Begin_Milepost}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-start ps-2">End Reference Marker</div>
            </div>
            <div className="col-lg-6">
              <div id="basic-info-emp" className="text-end pe-2">
                {corridorObject.End_Milepost}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="text-start ps-2">Overall Score</div>
            </div>
            <div className="col-lg-6">
              <div id="basic-info-score" className="text-end pe-2">
                {getOverallScore(corridorObject)?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div id="score-info" className="px-5 py-3">
          <div className="row">
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Pavement.svg" alt="Pavement" style={{ width: 75, height: 75 }} />
              <div className="icon-text">Pavement Score</div>
              <div id="score-info-1" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_1?.toFixed(2)}
              </div>
            </div>
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Bridge.svg" alt="Bridge" style={{ width: 75, height: 75 }} />
              <div>Bridge Score</div>
              <div id="score-info-2" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_2?.toFixed(2)}
              </div>
            </div>
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Safety.svg" alt="Safety" style={{ width: 75, height: 75 }} />
              <div className="icon-text">Safety Score</div>
              <div id="score-info-3" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_3?.toFixed(2)}
              </div>
            </div>
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Congestion.svg" alt="Congestion" style={{ width: 75, height: 75 }} />
              <div className="icon-text">Congestion Score</div>
              <div id="score-info-4" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_4?.toFixed(2)}
              </div>
            </div>
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Economic.svg" alt="Economic" style={{ width: 75, height: 75 }} />
              <div className="icon-text">Economic Score</div>
              <div id="score-info-5" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_5?.toFixed(2)}
              </div>
            </div>
            <div className="col-lg-2 text-center custom-column">
              <img src="assets/svg/Connectivity.svg" alt="Connectivity" style={{ width: 75, height: 75 }} />
              <div className="icon-text">Connectivity Score</div>
              <div id="score-info-6" className="py-1" style={{ backgroundColor: '#E9ECEF', width: '100%' }}>
                {corridorObject.Scr_6?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>

        <div id="metric-info" className="px-5">
          <div className="accordion" id="accordionExample">
            <div className="mt-1">
              <Accordion open={open} toggle={toggle}>
                <AccordionItem>
                  <AccordionHeader targetId="1">Pavement</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="1">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">Pavement Condition Rating</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-pavement-condition" className="text-end pe-2">
                          {corridorObject.PaveCond?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">% Pavement with Pavement Condition Score &lt; 60</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-pavement-hotspot" className="text-end pe-2">
                          {toPercentage(corridorObject.PaveLess60)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="2">Bridge</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="2">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">Bridge Sufficiency Rating</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-bridge-sufficiency" className="text-end pe-2">
                          {corridorObject.BridgeSuff?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">% Deck Area with Sufficiency Score &lt; 60</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-bridge-hotspot" className="text-end pe-2">
                          {toPercentage(corridorObject.Bridge_60)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="3">Safety</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="3">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">K&amp;A Crash Rate</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-crashRateKA" className="text-end pe-2">
                          {corridorObject.KA_Crash?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">Total Crash Rate</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-crashRateTot" className="text-end pe-2">
                          {corridorObject.TotalCrash?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="4">Congestion</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="4">
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">% Count Station with Existing V/C &gt; 0.8</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-congestion-exVC" className="text-end pe-2">
                          {toPercentage(corridorObject.ExistingVC, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">% Count Station with Future V/C &gt; 0.8</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-congestion-fuVC" className="text-end pe-2">
                          {toPercentage(corridorObject.FutureVC, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">Top 100 Congested Roadways (all vehicles)</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-congestion-top100all" className="text-end pe-2">
                          {toPercentage(corridorObject.T100_All, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="text-start ps-3">Top 100 Congested Roadways (trucks only)</div>
                      </div>
                      <div className="col-lg-2">
                        <div id="metric-congestion-top100truck" className="text-end pe-2">
                          {toPercentage(corridorObject.T100_Truck, 1)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="5">Economic</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="5">
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Freight Volume</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-truckAdt" className="text-end pe-2">
                          {parseInt(corridorObject.FreightVol).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Commodity Flow ( ton / year)</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-comFlow" className="text-end pe-2">
                          {parseInt(corridorObject.CommFlow) === 0
                            ? 'N/A'
                            : parseInt(corridorObject.CommFlow).toLocaleString()}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Job Density (job/ sqmi)</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-jobDens" className="text-end pe-2">
                          {parseInt(corridorObject.ExistEmp)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Population Density (resident / sqmi)</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-popDens" className="text-end pe-2">
                          {parseInt(corridorObject.ExistPop)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Projected Annual Traffic Growth Rate</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-trafGrowthRate" className="text-end pe-2">
                          {toPercentage(corridorObject.ProjGrowth)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Privately Held Land</div>
                      </div>
                      <div className="col">
                        <div id="metric-economic-privateLand" className="text-end pe-2">
                          {toPercentage(corridorObject.PrivateLan, 1)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader targetId="6">Connectivity</AccordionHeader>
                  <AccordionBody className="py-0" accordionId="6">
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">Traffic Generator Density</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-trafGenDens" className="text-end pe-2">
                          {corridorObject.MultiModal?.toFixed(2)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Hurricane Evacuation Route</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-evacRoute" className="text-end pe-2">
                          {toPercentage(corridorObject.Hurricane, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% International Trade Corridor</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-evacRoute" className="text-end pe-2">
                          {toPercentage(corridorObject.TradeCorr_Length, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Regional Trade Corridor</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-nationalFreightNetwork" className="text-end pe-2">
                          {toPercentage(corridorObject.RegTradeCorr_Length, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Local Trade Corridor</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-evacRoute" className="text-end pe-2">
                          {toPercentage(corridorObject.LocTradeCorr_Length, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% National Freight Network</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-nationalFreightNetwork" className="text-end pe-2">
                          {toPercentage(corridorObject.US_Freight, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Texas Freight Network</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-texasFreightNetwork" className="text-end pe-2">
                          {toPercentage(corridorObject.TX_Freight, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Energy Sector Route</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-energySecRoute" className="text-end pe-2">
                          {toPercentage(corridorObject.EnergySect)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Strategic Highway Network</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-stratHwyNetwork" className="text-end pe-2">
                          {toPercentage(corridorObject.Strat_Hwy, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Trunk System</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-trunkSys" className="text-end pe-2">
                          {toPercentage(corridorObject.Trunk_Sys, 1)}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="text-start ps-3">% Trunk System (Phase 1)</div>
                      </div>
                      <div className="col">
                        <div id="metric-connectivity-trunkPh1" className="text-end pe-2">
                          {toPercentage(corridorObject.Trunk_Ph1, 1)}
                        </div>
                      </div>
                    </div>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorridorInformation;
