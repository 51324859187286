import { useMemo } from 'react';

import { orderBy } from 'lodash';

import { DROPDOWN_LIST } from '../../../../Utils/Constants';
import TableWithPagination from '../../../../Components/TableWithPagination/TableWithPagination';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { getNeedValue } from '../../../../Utils/Functions';
import { getIndexColorCode } from '../../helper';
import DropDown from './DropDown';
import ColorCodeExplainer from '../../../../Components/ColorCodeExplainer/ColorCodeExplainer';

const HEADER_COLOR = {
  textAlign: 'center',
  font: 'normal 14px/16px Franklin Gothic',
  letterSpacing: '0px',
  color: '#000',
  opacity: 1,
  backgroundColor: '#fff',
  border: 'none',
  fontWeight: 'bold',
};

const performanceColorCodeDefinition = [
  {
    color: '#92D050',
    text: 'Good Performance',
  },
  {
    color: '#FEC000',
    text: 'Fair Performance',
  },
  {
    color: '#FF0000',
    text: 'Poor Performance',
  },
];

const needColorCodeDefinition = [
  {
    color: '#707070',
    text: 'No Need',
  },
  {
    color: '#D9F1FD',
    text: 'Low Need',
  },
  {
    color: '#81B4E1',
    text: 'Medium Need',
  },
  {
    color: '#25306E',
    text: 'High Need',
  },
];

const customOrder = (data, attribute) => {
  return orderBy(
    data,
    item => {
      const value = item.attributes[attribute];
      return value === 'N/A' ? -Infinity : value;
    },
    ['desc'],
  );
};

const SegmentMapOverview = ({ setIsTableView, setIsSegmentDetailViewOpen, allCorridorData, setHighlightSegment }) => {
  const { selectedSwitchValue, selectedDropDownValue, setSelectedSwitchValue, setSelectedDropDownValue } =
    useSegmentEvalutionStore(state => state);
  const isNeedSelected = selectedSwitchValue === 'Need';
  const colorCodeDefinition = isNeedSelected ? needColorCodeDefinition : performanceColorCodeDefinition;

  const handleSegmentClick = segment => {
    setSelectedSwitchValue('Performance');
    setSelectedDropDownValue('Pavement');
    setIsSegmentDetailViewOpen(true);
    setIsTableView(true);
    setHighlightSegment(segment);
  };

  const filteredData = useMemo(() => {
    const needValue = getNeedValue(selectedDropDownValue, selectedSwitchValue);
    console.log('needValue', needValue);
    const sortedData = isNeedSelected
      ? customOrder(allCorridorData, needValue)
      : orderBy(allCorridorData, [`attributes.Segment`], ['asc']);
    const dropdownSelectedValue = DROPDOWN_LIST.find(item => item?.label === selectedDropDownValue);
    return sortedData.map((corridor, i) => {
      const { value, backgroundColor, color } = getIndexColorCode(
        corridor?.attributes?.[needValue],
        selectedDropDownValue,
        selectedSwitchValue,
        corridor,
      );

      return {
        ...(isNeedSelected && {
          Rank: {
            __value: i + 1,
            __style: {
              background: '#ECEDED 0% 0% no-repeat padding-box',
              opacity: 1,
              textAlign: 'center',
              font: 'bold normal normal 24px/28px Franklin Gothic',
              letterSpacing: '0px',
              color: '#F68B1F',
              width: '60px',
            },
            __headerStyle: HEADER_COLOR,
          },
        }),
        '': {
          __innerHTMLValue: `<span class="segment">Segment ${corridor.attributes.Segment}</span><br /><span class="mainline-type">${corridor.attributes.Facility_T}</span>`,
          __headerStyle: HEADER_COLOR,
          __style: {
            letterSpacing: '0px',
            color: '#000000',
            opacity: 1,
            cursor: 'pointer',
          },
          __onClick: () => handleSegmentClick(corridor.attributes.Segment),
        },
        'Begin Marker': {
          __value: corridor.attributes.BMP,
          __headerStyle: HEADER_COLOR,
          __style: {
            font: 'normal normal normal 14px Franklin Gothic',
            textAlign: 'center',
          },
        },
        'End Marker': {
          __value: corridor.attributes.EMP,
          __headerStyle: HEADER_COLOR,
          __style: {
            font: 'normal normal normal 14px Franklin Gothic',
            textAlign: 'center',
          },
        },

        'Overall Score': {
          __label: `<div class="table-header-img">
          <div class="img-header">
          <img
            src='${dropdownSelectedValue?.img_url}'
            alt="${dropdownSelectedValue?.value}"
          /></div>
        </div><span>${dropdownSelectedValue?.value} Need
            </span>`,
          __value: value,
          __style: {
            backgroundColor: backgroundColor,
            color: color,
            textAlign: 'center',
            font: 'bold normal normal 14px Franklin Gothic',
            letterSpacing: '0px',
            opacity: '1',
            width: '100px',
          },
          __headerStyle: HEADER_COLOR,
        },
      };
    });
  }, [
    allCorridorData,
    HEADER_COLOR,
    selectedDropDownValue,
    setIsSegmentDetailViewOpen,
    setIsTableView,
    selectedSwitchValue,
  ]);

  return (
    <div className="evalution-container">
      <div className={`d-flex justify-content-between px-3`} style={{ height: 'fit-content' }}>
        <div className="evalution-title">Segment Evaluation Results</div>
        <div className="toggle-btn" onClick={() => setIsTableView(true)}>
          <div className={`segment-toggle-btn`}>
            <i className="fa-solid fa-table"></i>
            <span>Table</span>
          </div>
        </div>
      </div>
      <div className="col-lg-6 pt-2 px-3 pb-2">
        <DropDown />
      </div>
      <ColorCodeExplainer colorCodeDefinition={colorCodeDefinition} />
      <div className="mt-3">
        <TableWithPagination data={filteredData || []} itemsPerPage={9} isPaginationAvail={true} />
      </div>
    </div>
  );
};

export default SegmentMapOverview;
