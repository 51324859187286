import { useState, useMemo, useCallback } from 'react';

import { sortBy, uniqBy, filter } from 'lodash';

import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { filterBySearchTerm } from '../../../../Utils/SelectViewHelper';

function CorridorDropdown({ allCorridorData, setSelectedCorridorData }) {
  const [searchInputValue, setSearchInputValue] = useState('');

  const { setSelectedCorridorId, selectedCorridorId, selectedMode, selectedDistrict } = useSegmentEvalutionStore(
    state => state,
  );

  const handleSelectCorridor = useCallback(
    value => {
      const objectWithDesiredDistrict = filter(allCorridorData, item =>
        selectedMode === 'District' ? item.attributes.Districts === selectedDistrict : true,
      );
      const objectsWithDesiredHWY = filter(objectWithDesiredDistrict, item => item.attributes.HWY === value);
      const sortedData = sortBy(objectsWithDesiredHWY, 'attributes.Segment');
      setSelectedCorridorData(sortedData);
      setSelectedCorridorId(value);
      setSearchInputValue('');
    },
    [allCorridorData, setSelectedCorridorId, selectedMode, selectedDistrict],
  );

  const dropdownItems = useMemo(() => {
    const filterdWords = allCorridorData.filter(word =>
      selectedMode === 'District' ? word.attributes.Districts.includes(selectedDistrict) : true,
    );

    const matchingWords = uniqBy(filterdWords, 'attributes.HWY').filter(
      word => filterBySearchTerm({ HWY: word.attributes.HWY }, searchInputValue.toLowerCase()) && word,
    );
    const sortedWords = sortBy(matchingWords, 'attributes.HWY');

    if (searchInputValue && matchingWords.length === 0) {
      return <li className="dropdown-item">No Corridor found.</li>;
    }

    return sortedWords.map((word, i) => (
      <li
        key={i}
        className="dropdown-item"
        value={word.attributes.HWY}
        onClick={() => handleSelectCorridor(word.attributes.HWY)}
      >
        {word.attributes.HWY}
      </li>
    ));
  }, [searchInputValue, allCorridorData, selectedMode, selectedDistrict]);

  return (
    <form className="">
      <div className="dropdown col-12 w-100">
        <button
          className="form-select btn corridor-dropdown"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {selectedCorridorId}
          {!selectedCorridorId && <span>Select a Corridor</span>}
        </button>
        <ul className="dropdown-menu pt-0 corridor-dropdown-items" aria-labelledby="dropdownMenuButton1">
          <div className="input-group p-2">
            <input
              type="text"
              className="form-control shadow-none corridor-dropdown-input"
              placeholder="Search (Example: IH0010, IH10, or I-10)"
              value={searchInputValue}
              onChange={e => setSearchInputValue(e.target.value)}
            />
            <span className="input-group-text corridor-dropdown-search-icon">
              <i class="fa fa-search" aria-hidden="true"></i>
            </span>
          </div>
          <div className="corridor-dropdown-list">{dropdownItems}</div>
        </ul>
      </div>
    </form>
  );
}

export default CorridorDropdown;
