import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';

const API_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  useEffect(() => {
    const loginWithPing = async () => {
      try {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${API_URL}/pingone/login`;
        document.body.appendChild(form);
        form.submit();
      } catch (error) {
        console.error('SSO Login Error:', error);
      }
    };

    loginWithPing();
  }, []);

  return (
    <div className="login-container">
      <img src="assets/svg/TXDOTlogo_white_small.png" alt="TxDOT Logo" width="140px" height="110px" />
      <div className="d-flex flex-row justify-content-center">
        <p>Redirecting to login...</p>
      </div>
    </div>
  );
};

export default Login;
