import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import { useSegmentEvalutionStore } from '../../../store/segment-evaluation/store';
import { zoomCorridor } from '../../../.../../Utils/MapHelpers';
import { getNeedValue, getUniqueValueRenderer } from '../../../Utils/Functions';

import SegmentTableOverview from './SegmentTableOverview';
import SegmentDetailsView from './SegmentDetailsView';
import SegmentMapOverview from './SegmentMapOverview';
import SegmentMapView from './SegmentMapView';
import useMapView from './Hooks/useMapView';
import useCorridorLayer from './Hooks/useCorridorLayer';

const EvaluationResult = () => {
  const [isFullMapView, setIsFullMapView] = useState(false);
  const [isTableView, setIsTableView] = useState(false);
  const [isSegmentDetailViewOpen, setIsSegmentDetailViewOpen] = useState(false);
  const { allCorridorData } = useCorridorLayer();
  const [highlightSegment, setHighlightSegment] = useState(null);

  const { selectedSwitchValue, selectedDropDownValue, selectedCorridorId, selectedDistrict } = useSegmentEvalutionStore(
    state => state,
  );

  const { viewRef, corridorLayerRef, mapContainerRef, isMapLoaded, corridorKeyMarkerLayerRef } = useMapView();

  useEffect(() => {
    if (corridorLayerRef.current && isMapLoaded) {
      setTimeout(() => {
        zoomCorridor(corridorLayerRef.current, viewRef.current);
      }, 500);
    }
  }, [corridorLayerRef.current, isMapLoaded, viewRef.current]);

  useEffect(() => {
    if (corridorLayerRef.current && isMapLoaded) {
      corridorLayerRef.current.renderer = getUniqueValueRenderer(
        selectedSwitchValue,
        selectedDropDownValue,
        getNeedValue(selectedDropDownValue, selectedSwitchValue),
        allCorridorData[0],
        highlightSegment,
      );
      corridorLayerRef.current.refresh();
    }
  }, [selectedDropDownValue, selectedSwitchValue, isMapLoaded, highlightSegment]);

  useEffect(() => {
    const getDefinitionExpression = () => {
      if (!selectedCorridorId) return null;
      const baseExpression = `HWY = '${selectedCorridorId}'`;
      if (highlightSegment) {
        if (selectedDistrict) {
          return `${baseExpression} AND Districts = '${selectedDistrict}' AND Segment = '${highlightSegment}'`;
        }
        return `${baseExpression} AND Segment = '${highlightSegment}'`;
      } else {
        if (selectedDistrict) {
          return `${baseExpression} AND Districts = '${selectedDistrict}'`;
        }
        return baseExpression;
      }
    };

    const definitionExpression = getDefinitionExpression();
    if (corridorLayerRef.current && viewRef.current) {
      corridorLayerRef.current.definitionExpression = definitionExpression;
      corridorKeyMarkerLayerRef.current.definitionExpression = definitionExpression;
      setTimeout(() => {
        zoomCorridor(corridorLayerRef.current, viewRef.current);
      }, 500);
    }
  }, [corridorLayerRef, highlightSegment, selectedCorridorId, selectedDistrict, corridorKeyMarkerLayerRef, viewRef]);

  return (
    <>
      <Row style={{ maxWidth: '100%' }} className="m-0 p-0">
        <Col md={isFullMapView ? 12 : isTableView ? 4 : 7} className="m-0 p-0">
          <SegmentMapView
            mapContainerRef={mapContainerRef}
            setIsFullMapView={setIsFullMapView}
            isFullMapView={isFullMapView}
            highlightSegment={highlightSegment}
          />
        </Col>
        <Col md={isFullMapView ? 0 : isTableView ? 8 : 5} className="m-0 p-0">
          {isTableView ? (
            <>
              {isSegmentDetailViewOpen ? (
                <SegmentDetailsView
                  setIsTableView={setIsTableView}
                  setIsSegmentDetailViewOpen={setIsSegmentDetailViewOpen}
                  allCorridorData={allCorridorData}
                  highlightSegment={highlightSegment}
                  setHighlightSegment={setHighlightSegment}
                />
              ) : (
                <SegmentTableOverview
                  setIsTableView={setIsTableView}
                  allCorridorData={allCorridorData}
                  setHighlightSegment={setHighlightSegment}
                  setIsSegmentDetailViewOpen={setIsSegmentDetailViewOpen}
                />
              )}
            </>
          ) : (
            <SegmentMapOverview
              setIsTableView={setIsTableView}
              setIsSegmentDetailViewOpen={setIsSegmentDetailViewOpen}
              allCorridorData={allCorridorData}
              setHighlightSegment={setHighlightSegment}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default EvaluationResult;
