const downloadCSV = csvContentData => {
  let csvContent = '';
  csvContentData.forEach(row => {
    csvContent += row.join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  window.open(objUrl);
};

const getCSVHeaders = includeFullDetails => {
  if (includeFullDetails) {
    return [
      [
        'Rank',
        'ID',
        'Corridor Name',
        'Begin Marker',
        'End Marker',
        'Description',
        'Overall Score',
        'Pavement Score',
        'Bridge Score',
        'Safety Score',
        'Congestion Score',
        'Economic Score',
        'Connectivity Score',
        'Pavement Condition - Metric Score',
        'Pavement Hotspot - Metric Score',
        'Pavement Condition Rating',
        '% Pavement with Pavement Condition Score < 60',
        'Bridge Sufficiency - Metric Score',
        'Bridge Hotspot - Metric Score',
        'Bridge Sufficiency Rating',
        '% Deck Area on Bridges with Suff Rating < 60',
        'K&A Crash - Metric Score',
        'Total Crash - Metric Score',
        'K&A crash rate for entire corridor',
        'Total crash rate for entire corridor',
        'Existing V/C > 0.80 - Metric Score',
        'Future V/C >0.80 - Metric Score',
        'Top 100 Congested for All Vehicles - Metric Score',
        'Top 100 Congested for Trucks - Metric Score',
        '% Count Stations with Existing V/C > 0.80',
        '% Count Stations with Future V/C > 0.80',
        '% Corridor on Top 100 Congested for All Vehicles',
        '% Corridor on Top 100 Congested for Trucks',
        'Freight Volume - Metric Score',
        'Commodity Flow - Metric Score',
        'Existing Employment - Metric Score',
        'Existing Population - Metric Score',
        'Traffic Growth Rate - Metric Score',
        '% Privately Held Land - Metric Score',
        'Weighted average of daily truck volume data',
        'Weighted average of Transearch commodity flow data',
        'Employment density within 25 miles of corridor',
        "Population density in TAZ's within 25 miles of corridor",
        'Projected annual traffic growth rate',
        '% of Privately held land',
        'Access to Existing Multi-modal Facilities or Major Traffic Generators - Metric Score',
        'Part of Hurricane Evacuation Route - Metric Score',
        'Part of National Freight Network or TxDOT Primary Freight Network - Metric Score',
        'Part of Energy Sector Route - Metric Score',
        'Part of Strategic Highway Network - Metric Score',
        'Part of TxDOT Texas Trunk System - Metric Score',
        'Part of Trade Corridor Metric Score- Metric Score',
        'Density of existing sports arenas; ports; airports; rail stations; college/university; tourist site; etc.',
        '% of corridor that is hurricane evacuation route',
        '% of corridor that is on National Freight Network',
        '% of corrdor that is on TxDOT Primary Freight Network',
        '% of corridor that is Energy Sector Route',
        '% of corridor that is Strategic Highway Network',
        '% corridor that is TxDOT Phase I Trunk System',
        '% corridor that is TxDOT Trunk System',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        '',
        'score (0-100)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'numeric',
        '(%)',
        'score (0-10)',
        'score (0-10)',
        'numeric',
        '(%)',
        'score (0-10)',
        'score (0-10)',
        'numeric',
        'numeric',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        '(%)',
        '(%)',
        '(%)',
        '(%)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'numeric',
        'numeric',
        'numeric',
        'numeric',
        '(%)',
        '(%)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'score (0-10)',
        'numeric',
        '(%)',
        '(%)',
        '(%)',
        '(%)',
        '(%)',
        '(%)',
        '(%)',
      ],
    ];
  }
  return [
    [
      'Rank',
      'Corridor Name',
      'Route',
      'BMP',
      'EMP',
      'Description',
      'Overall Score',
      'Pavement Score',
      'Bridge Score',
      'Safety Score',
      'Congestion Score',
      'Economic Score',
      'Connectivity Score',
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      'score (0-100)',
      'score (0-10)',
      'score (0-10)',
      'score (0-10)',
      'score (0-10)',
      'score (0-10)',
      'score (0-10)',
    ],
  ];
};

const generateCorridorRows = (globalObject, includeFullDetails) => {
  return globalObject.map((corridor, index) => {
    const corridorRow = [
      index + 1,
      corridor.Corridor_ID.toString(),
      corridor.HWY.toString(),
      corridor.Begin_Milepost.toString(),
      corridor.End_Milepost.toString(),
      corridor.Description.toString(),
      corridor.Scr_Tot.toString(),
      corridor.Scr_1.toString(),
      corridor.Scr_2.toString(),
      corridor.Scr_3.toString(),
      corridor.Scr_4.toString(),
      corridor.Scr_5.toString(),
      corridor.Scr_6.toString(),
    ];

    if (includeFullDetails) {
      corridorRow.push(
        corridor.Scr_1_1.toString(),
        corridor.Scr_1_2.toString(),
        corridor.PaveCond.toString(),
        corridor.PaveLess60.toString(),
        corridor.Scr_2_1.toString(),
        corridor.Scr_2_2.toString(),
        corridor.BridgeSuff.toString(),
        corridor.Bridge_60.toString(),
        corridor.Scr_3_1.toString(),
        corridor.Scr_3_2.toString(),
        corridor.KA_Crash.toString(),
        corridor.TotalCrash.toString(),
        corridor.Scr_4_1.toString(),
        corridor.Scr_4_2.toString(),
        corridor.Scr_4_3.toString(),
        corridor.Scr_4_4.toString(),
        corridor.ExistingVC.toString(),
        corridor.FutureVC.toString(),
        corridor.T100_All.toString(),
        corridor.T100_Truck.toString(),
        corridor.Scr_5_1.toString(),
        corridor.Scr_5_2.toString(),
        corridor.Scr_5_3.toString(),
        corridor.Scr_5_4.toString(),
        corridor.Scr_5_5.toString(),
        corridor.Scr_5_6.toString(),
        corridor.FreightVol.toString(),
        corridor.CommFlow.toString(),
        corridor.ExistEmp.toString(),
        corridor.ExistPop.toString(),
        corridor.ProjGrowth.toString(),
        corridor.PrivateLan.toString(),
        corridor.Scr_6_1.toString(),
        corridor.Scr_6_2.toString(),
        corridor.Scr_6_3.toString(),
        corridor.Scr_6_4.toString(),
        corridor.Scr_6_6.toString(),
        corridor.Scr_6_5.toString(),
        corridor.Scr_6_7?.toString(),
        corridor.MultiModal.toString(),
        corridor.Hurricane.toString(),
        corridor.US_Freight.toString(),
        corridor.TX_Freight.toString(),
        corridor.EnergySect.toString(),
        corridor.Strat_Hwy.toString(),
        corridor.Trunk_Ph1.toString(),
        corridor.Trunk_Sys.toString(),
      );
    }

    return corridorRow;
  });
};

const generateCSVFile = (globalObject, includeFullDetails = true) => {
  const csvContentData = getCSVHeaders(includeFullDetails);
  const corridorRows = generateCorridorRows(globalObject, includeFullDetails);
  csvContentData.push(...corridorRows);

  downloadCSV(csvContentData);
};

export const generateCSV = globalObject => {
  generateCSVFile(globalObject, true);
};

export const generatePartialCSV = globalObject => {
  generateCSVFile(globalObject, false);
};
