import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import { getCookie } from './Utils/cookies';

const API_URL = process.env.REACT_APP_API_URL;

const logError = async (error, info) => {
  let accessToken = getCookie('access_token');

  await fetch(API_URL + '/errorLog', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      error: `${error.toString()}\nUser: ${localStorage.getItem('Username')}`,
      errorInfo: info,
    }),
  });
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary fallback={<ErrorPage />} onError={logError}>
    <App />
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
