import React, { useState } from 'react';

import CustomAccordion from '../../../../Components/Accordion/Accordion';
import { useAuth } from '../../../../Context/AuthContext';
import { useSegmentEvalutionStore } from '../../../../store/segment-evaluation/store';
import { useCorridorSelectionContext } from '../Context/CorridorSelectionContext';
import LoadingSpinner from '../../../../Components/LoadingSpinner/LoadingSpinner';
import { findMinMaxMileposts } from '../../../../Utils/Functions';

const uid = function () {
  return Date.now().toString(36) + crypto.getRandomValues(new Uint16Array(1)).toString(36).substring(2);
};

const CorridorCreation = ({ setCreateNewSelected, setSelectedCustomData, fetchData }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [disableCreatenew, setdisableCreatenew] = useState(true);
  const [accordionData, setAccordionData] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;
  const { authenticatedAxios, isAuthenticated } = useAuth();
  const { selectViewData } = useCorridorSelectionContext();
  const { selectedMode } = useSegmentEvalutionStore(state => state);

  const handleNewCorridorContentChange = newcorridordata => {
    const corridors = newcorridordata.map(item => {
      if (item && item.selectedOption !== '' && item.selectedOption !== undefined) {
        const originalData = selectViewData[item.selectedOption];
        const { minBegin, maxEnd } = findMinMaxMileposts(originalData);
        return {
          Corridor_ID: originalData[0].Corridor_ID,
          begin: item.begin ? item.begin : minBegin,
          end: item.end ? item.end : maxEnd,
          HWY: item.selectedOption,
          id: item.id,
        };
      }
      return null;
    });
    if (corridors[0] === null) {
      return;
    }
    setSelectedCustomData(corridors);
    localStorage.setItem('newcorridordata', corridors);
  };

  const handleContentChange = (id, field, value) => {
    return setAccordionData(previousAccordionData => {
      const updatedAccordionData = previousAccordionData.map(accordion => {
        if (accordion.id === id) {
          return { ...accordion, [field]: value };
        }
        return accordion;
      });
      handleNewCorridorContentChange(updatedAccordionData);
      return updatedAccordionData;
    });
  };

  const handleCreate = async () => {
    const errors = validateAccordionData();
    if (errors.length === 0) {
      try {
        if (!isAuthenticated) {
          return;
        }

        setIsCreating(true);
        let createCorridor = [];
        for (const segment of accordionData) {
          createCorridor.push({
            HWY: segment.selectedOption,
            BMP: Number(segment.begin),
            EMP: Number(segment.end),
            description: segment.description,
            user: localStorage.getItem('Username'),
          });
        }
        const response = await authenticatedAxios.post(API_URL + `/createresults`, createCorridor);
        await fetchData();
        setIsCreating(false);
        setCreateNewSelected(false);
        console.log('Response from server:', response.data);
      } catch (error) {
        setIsCreating(false);
        console.error('Error updating new segment:', error);
      }
    } else {
      console.log('Validation errors:', errors);
      setValidationErrors(errors);
    }
  };

  const handleAddAccordion = () => {
    const newAccordionId = uid();
    const newAccordionData = [
      ...accordionData,
      {
        id: newAccordionId,
        description: '',
        begin: '',
        end: '',
        selectedOption: '',
      },
    ];
    setAccordionData(newAccordionData);
    setdisableCreatenew(false);
  };

  const handleDeleteAccordion = id => {
    const updatedAccordions = accordionData.filter(accordion => accordion.id !== id);
    if (!updatedAccordions || updatedAccordions.length === 0) {
      setdisableCreatenew(true);
    } else {
      setdisableCreatenew(false);
    }
    setSelectedCustomData(prev => {
      return prev.filter(item => item.id !== id);
    });
    setAccordionData(updatedAccordions);
  };

  const validateAccordionData = () => {
    const errors = [];
    accordionData.forEach(accordion => {
      if (!accordion.description) {
        errors.push({
          id: accordion.id,
          fields: ['description'],
        });
      }
      if (!accordion.begin) {
        errors.push({
          id: accordion.id,
          fields: ['begin'],
        });
      }
      if (!accordion.end) {
        errors.push({
          id: accordion.id,
          fields: ['end'],
        });
      }
      if (!accordion.selectedOption) {
        errors.push({
          id: accordion.id,
          fields: ['selectedOption'],
        });
      }
    });
    return errors;
  };
  const clearValidationError = (id, field) => {
    setValidationErrors(prevErrors => prevErrors.filter(error => !(error.id === id && error.fields.includes(field))));
  };

  const hasError = validationErrors.length > 0;

  const updateCreteNewButtonState = isValid => {
    setdisableCreatenew(!isValid);
  };

  if (isCreating) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="container">
        <div className="create-new-wrapper">
          <div className="row">
            <div className="container-title">Create New</div>
          </div>
          <hr className="divider" />
          {accordionData.map((accordion, index) => (
            <CustomAccordion
              key={accordion.id}
              index={index}
              id={accordion.id}
              description={accordion.description}
              begin={accordion.begin}
              end={accordion.end}
              corridors={selectViewData}
              selectedOption={accordion.selectedOption}
              onContentChange={handleContentChange}
              onDeleteAccordion={handleDeleteAccordion}
              selectedMode={selectedMode}
              validationErrors={validationErrors.filter(error => error.id === accordion.id)}
              clearValidationError={clearValidationError}
              updateCreteNewButtonState={updateCreteNewButtonState}
            />
          ))}
          {accordionData.length === 0 && (
            <div className="d-flex justify-content-between add-corridor-btn">
              <div> Add a Corridor</div>
              <i className="fa fa-plus" onClick={handleAddAccordion}></i>
            </div>
          )}

          <div className="buttons-container">
            <button className="close-button" onClick={() => setCreateNewSelected(false)}>
              Close
            </button>
            <button
              id="create-btn"
              className="create-button"
              disabled={hasError || disableCreatenew}
              onClick={handleCreate}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorridorCreation;
