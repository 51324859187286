import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie, setCookie, removeCookie } from '../Utils/cookies'; // Assuming these utility functions exist

const API_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const checkAuth = async () => {
      // Retrieve token from URL if available
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get('token');

      // Get token from cookie if it exists
      let accessToken = getCookie('access_token');

      // If token is in the URL, store it in the cookie
      if (tokenFromUrl && !accessToken) {
        accessToken = tokenFromUrl;
        setCookie('access_token', accessToken, { expires: 1 / 48 }); // 30 minutes = 1/48 of a day

        // Remove the token from the URL without refreshing the page
        const newUrl = window.location.pathname;
        window.history.replaceState({}, document.title, newUrl);
      }

      if (!accessToken) {
        setIsInitialized(true); // No token found, so mark as initialized
        return;
      }

      // Validate the token with the server
      try {
        const response = await axios.post(`${API_URL}/pingone/validateToken`, {
          withCredentials: true,
          data: {
            accessToken,
          },
        });

        const isValid = response?.data?.isValid;
        if (isValid) {
          localStorage.setItem('Username', response.data.user.id);
          setUserName(response.data.user.id);
          setIsAuthenticated(true);
        } else {
          // Token is invalid, remove it from cookie
          removeCookie('access_token');
          setIsAuthenticated(false);
        }
      } catch (error) {
        // In case of error, clear localStorage and mark the user as unauthenticated
        localStorage.clear();
        setIsAuthenticated(false);
        removeCookie('access_token');
      } finally {
        setIsInitialized(true);
      }
    };

    checkAuth();
  }, []);

  const logout = async () => {
    try {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${API_URL}/pingone/logout`;
      document.body.appendChild(form);
      form.submit();

      // Remove access token after logout
      removeCookie('access_token');
      localStorage.clear();
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const authenticatedAxios = axios.create({
    withCredentials: true,
  });

  authenticatedAxios.interceptors.request.use(
    (config) => {
      const token = getCookie('access_token'); 
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        logout,
        authenticatedAxios,
        isInitialized,
        userName,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
