import { localStorageGetWithExpiry } from './LocalStorage';
import { WEIGHTS_DEFAULT_VALUES } from './Constants';

export const getCorridorTierColors = (scoresArray, corridorScore) => {
  let percentileRank = getPercentileRank(scoresArray, corridorScore);
  let tier = getTier(percentileRank);
  return getTierColorCodes(tier);
};

export const getPercentileRank = (scoresArray, corridorScore) => {
  let s = 0;
  let b = 0;
  for (let k = 0; k < scoresArray?.length; k++) {
    if (scoresArray[k] < corridorScore) {
      s += 1;
    } else {
      b += 1;
    }
  }
  return s / (s + b - 1);
};

export const getTier = percentileRank => {
  if (percentileRank >= 0.9) return 1;
  if (percentileRank >= 0.7) return 2;
  if (percentileRank >= 0.5) return 3;
  if (percentileRank >= 0.3) return 4;
  return 5;
};

export const getCorridorNeedColors = (value, selectedNeed, switchValue, Facility_T) => {
  console.log('__selectedNeed', selectedNeed);
  console.log('__switchValue', switchValue);
  console.log('__Facility_T', Facility_T);
  if (switchValue === 'Need') {
    const rank = () => {
      if (value >= 2.5 || value === 'High') return 1;
      if (value >= 1.5 || value === 'Medium') return 3;
      if (value >= 0.5 || value === 'Low') return 4;
      return 5;
    };
    return getTierColorCodes(rank());
  } else {
    if (selectedNeed === 'Pavement') {
      if ((value >= 0 && value < 60) || value === 'N/A') {
        return {
          backgroundColor: '#FF0000',
          color: '#FFFFFF',
        };
      } else if (value >= 60 && value < 80) {
        return {
          backgroundColor: '#FEC000',
          color: '#000000',
        };
      } else if (value >= 80) {
        return {
          backgroundColor: '#92D050',
          color: '#000000',
        };
      }
    } else if (selectedNeed === 'Bridge') {
      if (value < 5) {
        return {
          backgroundColor: '#FF0000',
          color: '#FFFFFF',
        };
      } else if (value >= 5 && value < 6.5) {
        return {
          backgroundColor: '#FEC000',
          color: '#000000',
        };
      } else if (value >= 6.5 || value === 'NaN') {
        return {
          backgroundColor: '#92D050',
          color: '#000000',
        };
      }
    } else if (selectedNeed === 'Mobility') {
      if (Facility_T === 'Rural') {
        if (value < 0.56) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 0.56 && value < 0.76) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 0.76 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      } else if (Facility_T === 'Urban') {
        if (value < 0.71) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 0.71 && value < 0.89) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 0.89 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      }
    } else if (selectedNeed === 'Safety') {
      if (Facility_T === 'Rural') {
        if (value < 2.56) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 2.56 && value < 3.98) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 3.98 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      } else {
        if (value < 3.27) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 3.27 && value < 4.9) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 4.9 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      }
    } else if (selectedNeed === 'Freight') {
      if (Facility_T === 'Rural') {
        if (value < 0.1) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 0.1 && value < 0.52) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 0.52 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      } else if (Facility_T === 'Urban') {
        if (value < 0.14) {
          return {
            backgroundColor: '#92D050',
            color: '#000000',
          };
        } else if (value >= 0.14 && value < 1.27) {
          return {
            backgroundColor: '#FEC000',
            color: '#000000',
          };
        } else if (value >= 1.27 || value === 'NaN') {
          return {
            backgroundColor: '#FF0000',
            color: '#FFFFFF',
          };
        }
      }
    } else if (selectedNeed === 'Overall') {
      const rank = () => {
        if (value >= 2.5) return 1;
        if (value >= 1.5) return 3;
        if (value >= 0.5) return 4;
        return 5;
      };
      return getTierColorCodes(rank());
    } else {
      return {
        backgroundColor: '',
        color: '',
      };
    }
  }
};

export const getTierColorCodes = tier => {
  switch (tier) {
    case 1:
      return {
        backgroundColor: '#25306E',
        color: '#FFFFFF',
      };
    case 2:
      return {
        backgroundColor: '#0186A9',
        color: '#FFFFFF',
      };
    case 3:
      return {
        backgroundColor: '#81B4E1',
        color: '#FFFFFF',
      };
    case 4:
      return {
        backgroundColor: '#D9F1FD',
        color: '#000000',
      };
    case 5:
      return {
        backgroundColor: '#FFFFFF',
        color: '#000000',
      };
    default:
      return {
        backgroundColor: '#707070',
        color: '#000000',
      };
  }
};

export const getPercentile = (scoresArray, corridorScore) => {
  return ((scoresArray.filter(score => score <= corridorScore).length / scoresArray.length) * 100)?.toFixed(2);
};

export const getCutOffs = scoresArray => {
  let cutOffs = {};
  cutOffs.tier1 = calcQuartile(scoresArray, 90);
  cutOffs.tier2 = calcQuartile(scoresArray, 70);
  cutOffs.tier3 = calcQuartile(scoresArray, 50);
  cutOffs.tier4 = calcQuartile(scoresArray, 30);
  cutOffs.tier5 = calcQuartile(scoresArray, 0);
  return cutOffs;
};

function calcQuartile(arr, q) {
  let a = arr.slice();
  q = q / 100;
  let data = sortArr(a);
  let p = (data.length - 1) * q;
  let b = Math.floor(p);
  let remainder = p - b;
  if (data[b + 1] !== undefined) {
    return parseFloat(data[b]) + remainder * (parseFloat(data[b + 1]) - parseFloat(data[b]));
  } else {
    return parseFloat(data[b]);
  }
}

function sortArr(arr) {
  let ary = arr.slice();
  ary.sort(function (a, b) {
    return parseFloat(a) - parseFloat(b);
  });
  return ary;
}

export const getOverallScore = corridor => {
  const customWeights = localStorageGetWithExpiry('customWeights');

  let pavementWeight = WEIGHTS_DEFAULT_VALUES.pavement / 100;
  let bridgeWeight = WEIGHTS_DEFAULT_VALUES.bridge / 100;
  let safetyWeight = WEIGHTS_DEFAULT_VALUES.safety / 100;
  let congestionWeight = WEIGHTS_DEFAULT_VALUES.congestion / 100;
  let economicWeight = WEIGHTS_DEFAULT_VALUES.economic / 100;
  let connectivityWeight = WEIGHTS_DEFAULT_VALUES.connectivity / 100;

  if (customWeights) {
    pavementWeight = parseInt(customWeights.pavement) / 100;
    bridgeWeight = parseInt(customWeights.bridge) / 100;
    safetyWeight = parseInt(customWeights.safety) / 100;
    congestionWeight = parseInt(customWeights.congestion) / 100;
    economicWeight = parseInt(customWeights.economic) / 100;
    connectivityWeight = parseInt(customWeights.connectivity) / 100;
  }

  const calculatedOverallScore =
    corridor.Scr_1 * pavementWeight +
    corridor.Scr_2 * bridgeWeight +
    corridor.Scr_3 * safetyWeight +
    corridor.Scr_4 * congestionWeight +
    corridor.Scr_5 * economicWeight +
    corridor.Scr_6 * connectivityWeight;
  return calculatedOverallScore * 10;
};

export const getTierIntervalsPerMetric = (globalObject, totalCount) => {
  return {
    pavement: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_1'), totalCount),
    bridge: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_2'), totalCount),
    safety: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_3'), totalCount),
    congestion: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_4'), totalCount),
    economic: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_5'), totalCount),
    connectivity: prepareTierIntervals(sortMetricsDescending(globalObject, 'Scr_6'), totalCount),
  };
};

export const prepareTierIntervals = (metricScores, totalCount) => {
  let tiers = {};
  tiers.tier1 = metricScores[Math.round(totalCount / 10)];
  tiers.tier2 = metricScores[Math.round((totalCount / 10) * 3)];
  tiers.tier3 = metricScores[Math.round((totalCount / 10) * 5)];
  tiers.tier4 = metricScores[Math.round((totalCount / 10) * 7)];
  return tiers;
};

export const sortMetricsDescending = (globalObject, metricField) => {
  return globalObject?.map(x => x[metricField])?.sort((a, b) => (a < b ? 1 : -1));
};

export const getMetricTier = (score, metricIntervals) => {
  if (score >= metricIntervals.tier1) return 1;
  if (score >= metricIntervals.tier2) return 2;
  if (score >= metricIntervals.tier3) return 3;
  if (score >= metricIntervals.tier4) return 4;
  return 5;
};

export const getColorCode = (tier = 5) => {
  let colorCode = {
    color: 'black',
    backgroundColor: '#FFFFFF',
  };
  if (tier === 1) {
    colorCode.backgroundColor = '#5a8ac6';
  } else if (tier === 2) {
    colorCode.backgroundColor = '#96b5da';
  } else if (tier === 3) {
    colorCode.backgroundColor = '#d1dfef';
  } else if (tier === 4) {
    colorCode.backgroundColor = '#e3ecf5';
  }
  return colorCode;
};
